import "./privacy-policy.scss"

import { graphql } from "gatsby"
import * as React from "react"
import { Container } from "react-bootstrap"
import FindDreamJob from "../components/careers/finddreamjob"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import "./mainpage.scss"

const IndexPage = () => {
    const pageTitle = "Privacy"
    const pageDescription = "BTS privacy"
    const lang = 'EN'
    const meta = []
    const breadcrumbMap = [
        { title: "Privacy", url: "#" }
    ]
    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <div className="max-container-pages">
                    <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                    <Container fluid="xl">
                        <section className="section-privacy">
                            <h1> BLUE TRAIL SOFTWARE PRIVACY POLICY</h1>
                            <p>



                                This privacy policy takes effect on January 1, 2022<br></br><br></br>
                                This Privacy Policy describes how Blue Trail Software collects and uses personal
                                information about you when you visit our website. By “personal information” we mean
                                information that directly identifies you, such as your name, address, or email address. We are
                                committed to respecting your privacy rights and take that responsibility seriously. Our policy
                                applies only to our website, and not to any other websites you might access from our site,
                                which may collect and use personal information differently from our policy. In this policy, “we”
                                and “our” means Blue Trail Software Holding, Inc., and “you” means any person who visits our
                                website.<br></br><br></br>


                                <span>   WHAT INFORMATION WE COLLECT </span> <br></br>

                                We collect the following information by operating the site: wwww.bluetrail.software<br></br><br></br>


                                <span>  COOKIES AND AUTOMATIC COLLECTION</span>  <br></br>

                                Our servers automatically recognize visitors' domain names and IP addresses (the number
                                assigned to computers on the Internet). No personal information about you is revealed in this
                                process. Our site may also gather anonymous "traffic data" that does not personally identify
                                you, but that may be helpful for marketing purposes or for improving the services we offer.<br></br>

                                We also use "cookies," a standard feature of major browsers, that lets us store a small piece of
                                data on your computer about your visit to our site. Cookies provide usage data, like where and
                                how often you visit, which helps us learn which areas of our site are useful or need
                                improvement. We do not set any personally identifiable information in cookies, nor do we
                                employ any data capture mechanisms on our website other than cookies.<br></br>

                                And finally, we collect information you voluntarily entered to our WEB site to have us contact
                                you.<br></br><br></br>

                                <span>   HOW WE USE INFORMATION </span> <br></br>
                                Personal Information. We use your personal information to communicate with you and provide
                                services. We will not share your personal information with any third parties without your
                                consent, except as necessary to provide you with the services offered by us or to comply with
                                the law. We may use your personal information to verify your identity, to check your
                                qualifications, or to follow up with transactions initiated on our site. We may also use your
                                contact information to inform you of any changes to our site, or to send you additional
                                information about us.<br></br>


                                Anonymous Information. We use anonymous information to analyze our site traffic, but we do
                                not examine this information for individually identifying information. In addition, we may use
                                anonymous IP addresses to help diagnose problems with our server, to administer our site, or
                                to display the content according to your preferences. Traffic and transaction information may
                                also be shared with business partners and advertisers on an aggregate and anonymous basis.<br></br><br></br>

                                <span>  HOW WE SHARE INFORMATION </span> <br></br>
                                Disclosing Personal Information. We may disclose personal information if required to do so by
                                law or if we believe in good faith that such action is necessary (i) to defend or protect us from
                                harm or in legal proceedings, (ii) to protect and defend our rights, property, and security, and
                                the security of our site users, or (iii) to protect our users or the safety of the public. We may
                                also disclose personal information to certain independent contractors who assist us with the
                                execution of our agreements with you, provided they sign contracts promising to protect this
                                information using procedures reasonably equivalent to our own. <br></br><br></br>


                                <span> ACCESS TO INFORMATION </span>  <br></br>

                                We do not maintain any procedures for you to review or request changes to the information
                                that we collect about you, except that you may request that we remove all information about
                                you from our database by contacting us below.<br></br><br></br>


                                <span>  ONLINE TRACKING – DO NOT TRACK NOTICE</span>   <br></br>

                                Browsers Do Not Track Signals. While we strive to offer you choices when you use our website,
                                we do not track customers over time and across third party websites to provide targeted
                                advertising, and therefore do not respond to web browser Do Not Track signals, nor do we
                                promise that we will be able to receive or honor Do Not Track signals.<br></br><br></br>

                                California Privacy Rights. California law entitles residents to ask us for a notice describing what
                                categories of personal information we share with third parties for third parties’ direct
                                marketing purposes. Unless you request us to or consent to us doing so, we do not sell any
                                personal information to third parties for their own direct marketing purposes. If you have
                                questions about these practices, please contact us below.<br></br><br></br>

                                Children's Privacy. We are a general audience site and do not direct any of our content at
                                children under 13 years of age, nor do we knowingly request or collect personal information
                                from children without prior verifiable parental consent. If we learn or have reason to suspect
                                that a user is under age 13, we will promptly delete any personal information in that user's
                                account. <br></br><br></br>


                                <span> SECURITY </span>  <br></br>

                                We have security measures in place to prevent the loss, misuse, and alteration of the
                                information that we obtain from you, but we make no assurances about our ability to prevent
                                any such loss, misuse, to you or to any third party arising out of any such loss, misuse, or
                                alteration. <br></br><br></br>

                                <span>WEBSITE AREAS BEYOND OUR CONTROL </span>  <br></br>

                                Public Forums. Our site may include interactive forums such as message boards and chat
                                rooms. Any information disclosed in these areas becomes public information and you should
                                exercise caution before deciding to disclose personal information.<br></br>

                                Third Party Websites. Our site may contain links to other websites. If you choose to visit these
                                sites, we are not responsible for their privacy practices or content. You are responsible to
                                review the privacy policies at those websites to confirm that you understand and agree with
                                their policies.<br></br><br></br>

                                <span>CONTACT INFORMATION AND POLICY UPDATES </span>  <br></br>


                                Contacting Us. If you have any questions about this policy, our practices related to our site, or if
                                you would like to have us remove your information from our database, please contact us
                                through the contact us button available on our WEB site. <br></br>
                                Updates and Changes. We may change our privacy policy at any time, without further notice to
                                you, by posting a new version on our site, which will become effective on the date it is posted.
                                It is your responsibility to review our policy from time to time to ensure that you continue to
                                agree with all of its terms.







                            </p>
                        </section>
                    </Container>
                    <FindDreamJob></FindDreamJob>

                </div>
            </Layout>
        </>
    )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`